<template>
  <video ref="videoPlayer" style="width: 100%" class="video-js vjs-16-9"
         v-bind:style="{ visibility : isPlaying ? 'visible' : 'hidden' }" :id="'video'">
  </video>
</template>

<script>

export default {
  name: "RecordingPlayer",
  props: {
    source: null,
  },
  data() {
    return {
      isLoading: true,
      isPlaying: false,

      playerOptions: {
        controls: true,
        settings: ['quality', 'speed', 'loop'],
        fullscreen: {enabled: true, fallback: true, iosNative: true, container: null},
        autoplay: true,
      }

    }
  },

  mounted() {
    this.player = videojs(this.$refs.videoPlayer, this.playerOptions, function onPlayerReady() {
    })
    if(this.source) {
      this.setSource(this.source)
      return
    }
    this.player.hide()

  },

  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
  watch: {
    source: function(newVal) {
      this.setSource(newVal);
    }
  },

  methods: {

    setSource(src) {
      this.player.src({src: src, type: "video/mp4", label: "Recording"});
      this.player.show()
      this.isPlaying = true

      this.player.on('stalled', () => {
        console.log('stalled fired')
        if (!this.loading) {
          this.player.play()
        }
      })
      this.player.on('error', () => {
        console.log('error fired')
        this.player.play()
      })
    },

  }

}
</script>

<style>


.vjs-poster {
  background-size: 100% !important;
}


</style>
