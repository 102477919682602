/* packages.store.js */
import { saveAs } from 'file-saver';

// State object
const state = {
    all_packages: [],
}

// Getter functions
const getters = {
    getPackageByProjectId: (state) => (id) => {
        return state.all_packages.filter(pack => {
            return pack.project_id === id

        })
    },
    unusedPackages: (state) => {
        return state.all_packages.filter(pack => {
            return !pack.project_id
        })
    },
}

// Actions
const actions = {
    fetchPackages({commit}, params = "") {
        return new Promise((resolve, reject) => {
            this.$axios.get(`/api/packages?${params}`)
                .then((response) => {
                    commit('SET_PACKAGES', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    fetchPackageDetails({commit}, payload) {
        return new Promise((resolve, reject) => {
            this.$axios.get(`/api/packages/${payload.id}?${payload.params}`)
                .then((response) => {
                    //commit('UPDATE_OR_SET_PACKAGES', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    createPackage({commit}, formData) {
        return new Promise((resolve, reject) => {
            this.$axios.post("/api/packages", formData)
                .then((response) => {
                    commit('CREATE_PACKAGE', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error.response.data.message)
                })
        })
    },
    updatePackage({commit}, payload) {
        return new Promise((resolve, reject) => {
            this.$axios.patch(`/api/packages/${payload.package_id}`, payload.formData)
                .then((response) => {
                    commit('UPDATE_OR_SET_PACKAGES', response.data)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error.response.data.message)
                })
        })
    },
    fetchPackageAlerts({commit}, payload) {
        return new Promise((resolve, reject) => {
            this.$axios.get(`/api/projects/${payload.project_id}/alerts?package_id=${payload.package_id}${payload.params}`)
                .then((response) => {
                    //commit('UPDATE_OR_SET_PACKAGES', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    fetchProjectAlerts({commit}, payload) {
        return new Promise((resolve, reject) => {
            this.$axios.get(`/api/projects/${payload.project_id}/alerts${payload.params}`)
                .then((response) => {
                    //commit('UPDATE_OR_SET_PACKAGES', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    fetchAlertDetails({commit}, payload) {
        return new Promise((resolve, reject) => {
            this.$axios.get(`/api/projects/${payload.project_id}/alerts/${payload.alert_id}?include=recordings&syncRecordings=1`)
                .then((response) => {
                    //commit('UPDATE_OR_SET_PACKAGES', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    fetchProjectIncidents({commit}, payload) {
      return new Promise((resolve, reject) => {
        this.$axios.get(`/api/projects/${payload.project_id}/incidents${payload.params}`)
          .then((response) => {
            //commit('UPDATE_OR_SET_PACKAGES', response.data.data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    fetchPackageLogs({commit}, payload) {
        return new Promise((resolve, reject) => {
            this.$axios.get(`/api/packages/${payload.package_id}/logs?${payload.params}`)
                .then((response) => {
                    //commit('UPDATE_OR_SET_PACKAGES', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    deletePackage({commit}, id) {
        return new Promise((resolve, reject) => {
            this.$axios.delete(`/api/packages/${id}`)
                .then((response) => {
                    commit('DELETE_PACKAGE', id)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error.response.data.message)
                })
        })
    },
    downloadRecording({commit}, id) {
        var FileSaver = require('file-saver');

        return new Promise((resolve, reject) => {
            this.$axios.get(`/api/recordings/${id}/download`, {
                responseType: 'blob'
            } )
                .then((response) => {
                    FileSaver.saveAs(new Blob([response.data]), 'recording.mp4');

                    //commit('UPDATE_OR_SET_PACKAGES', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    streamRecording({commit}, id) {
        return new Promise((resolve, reject) => {
            this.$axios.get(`/api/recordings/${id}/stream`)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    updateAlert({commit}, payload) {
        return new Promise((resolve, reject) => {
            this.$axios.patch(`/api/projects/${payload.project_id}/alerts/${payload.alert_id}`, payload.formData)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error.response.data.message)
                })
        })
    },
    fetchCameraSnapshot({commit}, payload) {
        return new Promise((resolve, reject) => {
            this.$axios.get(`/api/projects/${payload.project_id}/cameras/${payload.camera_id}/snapshot?sync=${payload.sync}`)
                .then((response) => {
                    resolve( response.data);
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    fetchCameraStream({commit}, payload) {
        return new Promise((resolve, reject) => {
            this.$axios.get(`/api/projects/${payload.project_id}/cameras/${payload.camera_id}/stream`)
                .then((response) => {
                    resolve( response.data);
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },


    // ALL ADMIN STUFF
    fetchAllLogs({commit}, payload) {
        return new Promise((resolve, reject) => {
            this.$axios.get(`/api/logs?${payload.params}`)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    fetchAllAlerts({commit}, payload) {
        return new Promise((resolve, reject) => {
            this.$axios.get(`/api/alerts?${payload.params}`)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },



}

// Mutations
const mutations = {
    SET_PACKAGES(state, packages) {
        state.all_packages = packages
    },
    UPDATE_OR_SET_PACKAGES(state, current_package) {
        let index = state.all_packages.findIndex(a_package => a_package.id === current_package.id)
        if (index !== -1) {
            state.all_packages[index] = current_package
        }
        else {
            state.all_packages.unshift(current_package) // Add package to all_packages
        }
    },
    CREATE_PACKAGE(state, a_package) {
        state.all_packages.push(a_package)
    },
    DELETE_PACKAGE(state, id) {
        let index = state.all_packages.findIndex(pack => pack.id === id)
        state.all_packages.splice(index, 1)

    },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
