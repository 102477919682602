<template>
    <div @click="clicked"  style="z-index: 1" class="has-text-centered">
        <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                width="30px"
                height="30px"
                aria-labelledby="eye"
                role="presentation"
                style="z-index: 1"
        >
            <path id="background" d="M512,3.18,206.89,1.09S122.841,7.367,64.784,66.918C12.905,120.131,2.09,213.204,2.09,213.204L0,506.82l303.02,2.09s92.785-4.592,151.511-72.098c51.964-59.733,55.379-145.241,55.379-145.241Z"
                  transform="translate(0 -1.09)" fill="#fff"/>
            <path id="outer" d="M301,498,11,496l2-281S23.351,125.926,73,75c55.563-56.992,136-63,136-63l292,2-2,276s-3.268,81.833-53,139C389.798,493.605,301,498,301,498Zm-44.5-81a165,165,0,1,0-165-165A165,165,0,0,0,256.5,417Z"
                  transform="translate(0 -1.09)" :fill="powerStatus.color"/>
            <path id="inner" d="M311,252a54,54,0,1,0-54,54A54,54,0,0,0,311,252Z"
                  transform="translate(0 -1.09)" :fill="alarmStatus.color"/>

        </svg>
<!--        <p v-if="hasText">-->
<!--            <b-tag size="is-small" type="is-grey-light">{{id}}</b-tag>-->
<!--        </p>-->
    </div>
</template>

<script>
    import router from '../router'

    export default {
        name: "EyeIcon",
        props: ["status", "type", "id", "hasText"],

        computed: {
            powerStatus(){
                if(!this.status) return {
                    type: 'has-text-warning',
                    color: "#004977",
                    indicator: '',
                    pulse: false,
                }
                if(this.status.power_status === "no power" ){
                    return {
                        type: 'has-text-danger',
                        color: "#963137",
                        indicator: 'negative',
                        pulse: false,
                        msg: 'No AC'
                    }
                } else if(this.status.power_status === "partially powered"){
                    return {
                        type: 'has-text-danger',
                        color: "#963137",
                        indicator: 'intermediary',
                        pulse: false,
                    }
                } else if(this.status.power_status === "powered"){
                    return {
                        type: 'has-text-success',
                        color: "#9DC116",
                        indicator: 'positive',
                        pulse: false,
                    }
                } else {
                    return {
                        type: 'has-text-warning',
                        color: "#004977",
                        indicator: '',
                        pulse: false,
                    }
                }
            },
            alarmStatus(){
                if(!this.status) return {
                    type: 'has-text-warning',
                    color: "#004977",
                    indicator: '',
                    pulse: false,
                }
                if(this.status.alarm_status === "triggered" ){
                    return {
                        type: 'has-text-danger',
                        color: "#963137",
                        indicator: 'negative',
                        pulse: true,
                    }
                } else if(this.status.alarm_status === "armed"){
                    return {
                        type: 'has-text-danger',
                        color: "#963137",
                        indicator: 'negative',
                        pulse: false,
                    }
                } else if(this.status.alarm_status === "partially armed"){
                    return {
                        type: 'has-text-success',
                        color: "#9DC116",
                        indicator: 'intermediary',
                        pulse: false,
                    }
                } else if(this.status.alarm_status === "not armed"){
                    return {
                        type: 'has-text-success',
                        color: "#9DC116",
                        indicator: 'positive',
                        pulse: false,
                    }
                } else {
                    return {
                        type: 'has-text-warning',
                        color: "#004977",
                        indicator: '',
                        pulse: false,
                    }
                }
            },
        },

        methods: {
            clicked() {
                if (this.type === "package") {
                    router.push({ name:'package.detail', params: {id: this.id} })                }
                else if (this.type === "projects") {
                    router.push({ name:'project.detail', params: {id: this.id} })
                }
            },

        }
    }
</script>

<style lang="scss" scoped>

</style>