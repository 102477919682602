<template>

  <div class="box mr-4" style="overflow: hidden"   v-bind:class="{'has-table no-outside': thumb}">
    <div style="margin: -20px; cursor: pointer" class="card-image image image-container">

      <b-image class="theimage gradient"
               :src="img"
               alt="Image Loading"
               @click.native="imageModal"
               :ratio="'4by3'"
               :rounded="false"
      >

        <b-skeleton
            class="skeleton-placeholder"
            slot="placeholder"
            height="100%"
        ></b-skeleton>

      </b-image>

      <div style="width: 100%; position:relative; top:0;left:0;">
        <b-field style="position: absolute; bottom: 15px; left: 50%; margin-left:-100px; width: 300px ">
          <p class="control">
            <b-button style="opacity: 0.7" type="is-dark" icon-left="refresh"  size="is-small" rounded :loading="loading" @click.native="fetchData"></b-button>
          </p>
          <p class="control">
            <!--            <span style="opacity: 0.7" class="button is-dark is-static is-small">{{synced_at | moment("from", "now")}}</span>-->
            <b-button style="opacity: 0.7" type="is-dark"  size="is-small" >
              <status-indicator style="display: inline-block; margin-right: 0.4rem; margin-top: 0.2rem" type="positive" :pulse="true"></status-indicator>
              {{synced_at | moment("from", "now")}}</b-button>
          </p>
          <p class="control">
            <b-button v-can:has.disabled="`${project_id}.view livestream`"
                      style="opacity: 0.7"
                      type="is-dark"
                      icon-right="eye"
                      size="is-small"
                      rounded
                      @click.native="openStreamModal">

            </b-button>
          </p>

        </b-field>
      </div>

      <div  class="card-content is-overlay" style="bottom: auto; left: auto; padding: 0.5rem">
        <b-taglist attached>
          <b-tag style="opacity: 0.8"  type="is-dark" >{{name}}</b-tag>
        </b-taglist>
      </div>
    </div>

  </div>
</template>

<script>
    import packages from "@/store/modules/packages.store"
    import RefreshButton from "../../RefreshButton";
    import CameraStreamComponent from "./CameraStreamComponent";
    import CardComponent from "../../CardComponent";

    export default {
        name: "CameraSnapshotComponent",
        components: {CardComponent, RefreshButton},
        props: ['camera_id', 'project_id', 'name', 'thumb'],

        data() {
            return {
                loading: true,
                img: null,
                synced_at: null,
            }
        },
        created() {
            if(!packages.isRegistered) {
                this.$store.registerModule('packages', packages)
                packages.isRegistered = true
            }
        },

        mounted() {
            this.fetchData()
        },

        methods: {

            fetchData(sync=0) {
                this.loading  = true;
                this.$store.dispatch('packages/fetchCameraSnapshot', {project_id: this.project_id, camera_id: this.camera_id, sync: sync})
                    .then(response => {
                        this.img = response.img;
                        this.synced_at = response.synced_at
                        this.loading = false
                    })
            },

            imageModal() {
              const h = this.$createElement
              const vnode = h('p', { class: "image" }, [
                h('img', { attrs: { src: this.img }})
              ])
              this.$buefy.modal.open({
                content: [ vnode ],
                width: "90%"
              })
            },

            openStreamModal() {
                if(!this.thumb) return
                this.$buefy.modal.open({
                    parent: this,
                    component: CameraStreamComponent,
                    props: {
                        camera_id: this.camera_id,
                        project_id: this.project_id,
                        name: this.name,
                        snapshot_url: this.img
                    },
                    hasModalCard: false,
                    fullScreen: false,
                    customClass: "px-6 py-6 is-vcentered",
                    width: "90%",
                    trapFocus: false,
                    ariaRole: "dialog",
                    ariaModal: true,
                    scroll: "keep"
                })

            }


        },

    }
</script>

<style scoped>

    /deep/ .b-skeleton {
      height: 100%;
      position: absolute;
      top: 0;
    }
    /* Container needed to position the overlay. Adjust the width as needed */
    .image {
        position: relative;
    }


    /* Make the image to responsive */
    .theimage {
        display: block;
        width: 100%;
        height: 100%;
    }

    /* The overlay effect - lays on top of the container and over the image */
    .overlay {
        position: absolute;
        background: rgb(0, 0, 0);
        background: rgba(0, 0, 0, 0.4); /* Black see-through */
        color: #f1f1f1;
        width: 100%;
        height: 100%;
        transition: .5s ease;
        opacity:0.5;
        color: white;
        font-size: 15px;
        text-align: center;
        padding: 0;
        vertical-align: center;
    }

    /* When you mouse over the container, fade in the overlay title */
    .image:hover .overlay {
        opacity: 1;
    }
    .gradient {

    }

</style>
